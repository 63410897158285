<template>
  <div>
    <Main />
    <!-- <Blog /> -->
    <!-- <NewF /> -->
  </div>
</template>
<script>
import Main from "../components/farm/Main.vue";
// import Blog from "../components/home/Blog.vue";
// import NewF from "../components/homemain/NewF.vue";
export default {
  components: {
    // Header,
    Main,
    // Blog,
    // NewF
  },
};
</script>
